body{
  margin: 0px;
  padding: 0px;
  background-color:#f9f8f8f6;

}
#emailFooterDiv{
  text-align: center;
  width: 50vw;
  padding: 0px;
  margin: auto;
  padding-top: 10px;
}
#emailFooter{
  background-color: #e8ebebf6;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 100px;
  margin-top: 50px;
}
#emailTitel {
    text-align: center;
    padding-bottom: 25px;
    margin-top: 0px;
  }

  #emailInput {
    margin-bottom: 10px;
    height: 30px;
    border-radius: 10px;
    font-size: larger;
    width: 90%;
}
#emailError{
  color: red;
}

  #emailButton {
    margin-top: 20px;
    margin-left: 0;
    height: 40px;
    border-radius: 10px;
    font-size:medium;
    width: auto;
}
  .main{
    width: 50%;
    margin: auto;
    text-align: center;
    margin-top: 100px;
    height: 70vh;
  }
  #thankYou{
    margin-top: 90px;
    text-align: center;
    height: 80vh;

  }
  #thankYou h1{
    margin-left: 0px;
  }

  #thankYou h3{
    color: grey;
  }


  #emailContainer{
    height: 100vh;
    width: 100%;

  }

  @media (max-width: 828px) {
    .main{
      margin: auto;
      margin-top: 100px;
      width: 90%;
      text-align: center;
    }
    #emailInput{
      height: 40px;
      border-radius: 10px;
    }
    #emailButton {
      height: 40px;
      border-radius: 10px;
    }
  }
