.container {
    max-width: 100vw;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
  }
  #uni{
    color: rgba(255, 0, 0, 0.718);
  }
  .startSurvey{
    width: 80%;
    font-size: larger;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  #Modules{
    background-color: #f9f8f8f6;
 }
  #secondMod{
    background-color: #f9f8f8f6;
    margin: auto;
    height: 50vh;
    padding: 20px;
    width: 60%;

  }
  #secondModTxt{
    margin-top: 20px;

    float: right;
    width: 200px;    
  }
  #secondModImg{
    float: left;
    background-color: #ebebebf6;
    border-radius: 50px;
    padding: 50px;

  }
  #firstMod{
    padding: 20px;
    height: 50vh;
    background-color: #f9f8f8f6;
    margin: auto;
    width: 60%;

  }
  #firstModTxt{
    margin-top: 20px;
    float: left;
    width: 200px;    
  }
  #firstModImg{
    float: right;
    left: 0px;
    background-color: #ebebebf6;
    border-radius: 50px;
    padding: 50px;

  }
  #reviewImg{
    width: 200px;
  }
  #second-button{

  }
  h1 {
    margin-bottom: 20px;
    margin-left: 50px;
  }
  /* h5{
    background-color: rgba(255, 0, 0, 0.2); 
    padding-top: 10px;
    padding-bottom: 10px;
    width: 80%;
    margin: auto;
    margin-bottom: 40px;
  } */
  .header{
    height: 80px;
    background-color: #fff;
  }
  .title{
    float: left;
    font-weight: bolder;
  }
  .login-form {
    float: right;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }
  #unihat{
    width: 50px;
    height: 30px;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
  }
  .google-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 150px;
    margin-right: 50px;
  }
  
  .google-button:hover {
    background-color: #006adb;
  }
  
  .google-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .main-button {
    padding-top: 20px;
    background-color: #f9f8f8f6;
  }
  table {
    margin: auto;
    width: 80%;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
  }
  th {
    border-bottom: 1px solid #e7e3e3f6;
    border-top: 1px solid #e7e3e3f6;
  }
  tr{
    border-radius: 5px;
    font-size: larger;
  }
  #table tr:hover {
    background-color: rgb(228, 228, 228);
  }
  /* @media (max-width: 828px) {
    table{
      width: 95%;
    }
    h5{
      width: 95%;
    }
    .title {
      font-size: 0px;
      margin-top: 25px;
    }
    .startSurvey{
      width: 95%;
    }
    #secondMod{
        width: 90%;
        height: 550px;
    }
    #firstMod{
        width: 90%;
        height: 550px;
    }
    #firstModImg{
        float: none;
        margin: auto;
    }
    #firstModTxt{
        float: none;
        margin: auto;
    }
    #secondModImg{
        float: none;
        margin: auto;
    }
    #secondModTxt{
        float: none;
        margin: auto;
    }

  
  } */
