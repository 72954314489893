.container {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
  }
  body{
    font-family: Sans-serif, Verdana;
  }
  .pop-down {
    z-index: 5;
    position: relative;
    background-color: #f0f0f0;
    padding: 10px;
    overflow: hidden;
    max-height: 0;
    animation: popDownAnimation 0.5s ease forwards;
  }
  
  .pop-down.open {
    animation-name: popUpAnimation;
  }
  
  @keyframes popDownAnimation {
    0% {
      max-height: 200px; /* Adjust the height as desired */
    }
    100% {
      max-height: 0;
    }
  }
  
  @keyframes popUpAnimation {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px; /* Adjust the height as desired */
    }
  }
  .pop-down-title {
    margin: 0;
    padding: 5px;
  }
  
  .pop-down-text {
    margin: 0;
  }
  #closePopDown{
    border-radius: 10px;
    background-color: rgba(227, 83, 50, 0.631);
  }
  #businessmanInfographs{
    width: 600px;
    margin: auto;
    height: 270px;
    margin-top: 70px;
  }
  #businessmanImage{
    width: 150px;
    height: 150px;
    float: left;
  }
  #businessmanText{
    float: right;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
    font-weight: 100;
    max-width: 200px;

  }
  #businessmanText h3{
    font-weight: 500;
  }
  #reviewManInfograph{
    margin-top: 50px;
    width: 600px;
    margin: auto;
    height: 200px;
  }
  #reviewManImage{
    width: 150px;
    height: 150px;
    float: right;
  }
  #reviewManText{
    float: left;
    text-align: center;
    margin-top: 0px;
    margin-left: 10px;
    max-width: 200px;
    font-weight: 100;
  }
  #reviewManText h3{
    font-weight: 500;
  }

  #sortByLanding{
    border-radius: 10px;
    font-size: medium;
    font-weight: 100;
    display: block;
    margin: auto;
    margin-top: 20px;
  }
  #subHeaderLanding{
    padding: 10px;
    padding-bottom: 20px;
    margin: auto;
    max-width: 80%;
  }
  #textLanding{
    color: rgb(88, 88, 88);
    font-size: large;
    font-weight: 100;
    line-height:25px;
    width: 80%;
    max-width: 1000px;
    margin: auto;
    text-align: left;
  }
  #textReviewUniBanner{
    background-color: rgba(227, 83, 50, 0.631);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
    text-align: left;
    height: 100px;

  }
  #headingTextReview{
    padding-left: 20px;
    font-weight: 500;
    font-size: larger;

  }
  #subTextReview{
    font-size: medium;
    font-weight: 100;
    padding-left: 20px;

  }

  #actionReviewUniBanner{
    padding: 10px;
    text-align: left;
    height: 40px;
    padding-bottom: 25px;
    
  }
  #writeAReviewText{
    float: left;
    padding-left: 20px;
    text-decoration: underline;
    font-weight: 100;

  }
  #writeAReviewText:hover{
    cursor: pointer;
  }
  #writeAReviewStars{
    float: right;
    padding: 15px;
  }
  #writeReviewUniDiv{
    width: 80%;
    max-width: 1000px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    margin-top: 30px;
    margin-bottom: 30px;
  }
  #review-uni-button{
    background-color: rgba(227, 83, 50, 0.631);
    border-radius: 10px;
    width: 60%;
    max-width: 800px;
    font-size: medium;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
  }
  #sortBy{
    border-radius: 10px;
    font-size: medium;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .modal {
    position: fixed; /* Stay in place */
    margin: auto;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    z-index: 9999;
    text-align: left;
  }
  .modal-content {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
  }
  .modal-content h3{
    margin-top: 30px;
  }
  .modal-content input{
    padding-right: 50px;
    border-radius: 10px;
    display: block;
  }
  #sendEmail{
    margin-left: 0px;
    border-radius: 10px;
    background-color: rgb(227, 83, 50, 0.631);
    width: fit-content;
  }
  #x{
    font-size: large;
    position: inherit;
    width: 20px;
    text-align: right;
    right: 0px;
    margin-right: 10px;
    margin-top: 0px;
    padding-top: 0px;
    padding-right: 25px;
    background-color: white;
    color: grey;
  }
  #userEmail{
    width: 350px;
  }
  #emailError{
    margin:0px;
    padding-bottom: 5px;
    font-size: small;
    color: rgb(227, 83, 50, 0.631);
  }
  #emailSubInput{
    border-radius: 10px;
    font-size: medium;
    padding: 10px;
    width: 380px;
    margin-top: 15px;
  }
  #footer{
    position: relative;
    text-align: center;

    width: 50vw;
    padding: 0px;
    margin: auto;
    padding-top: 10px;
  }
  footer .title{
    float: none;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  footer{
    background-color: #e8ebebf6;
    margin-bottom: 0px;
    padding-bottom: 0px;
    height: 200px;
    margin-top: 100px;
  }
  #titleSubmission{
    color: rgba(227, 83, 50, 0.631);
    font-size: 30px;
    padding-bottom: 20px;
    font-weight: 500;
  }
  #rating{
    padding-bottom: 10px;
  }
  #conz{
    font-size: large;
    padding: 0px;
    margin: 0px;
  }
  #index{
    font-weight: bolder;
  }
  #LoginReviews{
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    color: rgb(69, 69, 69);

  }
  #LoginReviews:hover{
    cursor: pointer;
  }
  #submissionSpeil{
    padding-bottom: 15px;
  }

  #submissionElements{
    width: 450px;
    margin: auto;
    text-align: left;
    font-family: verdana, sans-serif;
    font-weight: 100;
    padding-left: 10px;
  }
  #submissionElements p, label{
    font-size: 14px;
    line-height: 25px;
    font-weight: 100;
    text-align: left;
    margin: 0px;
  }
  #titleSubmission{
    text-align: left;
    margin: 0px;
    margin-top: 10px;
    border-bottom: 1px solid rgb(214, 214, 214);
    margin-bottom: 20px;
  }
  #submissionForm form{
    margin-top: 20px;
    text-align: left;
    align-items: start;
  }
  .AddSubmissionButtons{
    border-radius: 10px;
    width: 130px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 100px;
    height: 50px;
    font-size: larger;
    float: right;
    position: relative;
    background-color: rgba(227, 83, 50, 0.631);
  }
  .AddSubmissionButtons:hover{
    background-color: rgba(169, 59, 35, 0.631);
  }
  #submissionSubmitButton{
    margin: left;
    width: 80%;
  }
  #submissionElements select{
    padding: 10px;
    border-radius: 7px;
    margin-top: 10px;
    width: 400px;
  }
  #selectUniDegree{
    color: rgb(103, 103, 103);
    font-size: medium;
    cursor: pointer;
  }
  #lastNotes{
    border-radius: 5px;
    height: 200px;
    width: 400px;
    text-rendering: auto;
    margin-top: 10px;
  }
  #reviewTable{
    margin-left: 0;
    font-size: small;
  }
  #reviewTable td{
    padding: 0px;
    font-weight: bold;
  }
  #averagesReviewTable{
    width: 100%;
    font-weight: 100;
    font-size: medium;
    line-height: 20px;
  }
  #averagesReviewTable td{
    padding: 0px;
  }
  #averagesReviewTable tr:hover{
    cursor: default;
  }
  #reviewTable tr:hover{
    cursor: default;

  }
  #leftColumn{
    width: 200px;
  }


  .emblem{
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
  #rightArrow{
    height: 15px;
    width: 30px;
    margin-left: 50px;
    padding-bottom: 4px;

  }
  .startSurvey{
    width: 80%;
    font-size: larger;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  h1 {
    margin-bottom: 20px;
    margin-left: 50px;
  }
  .header{
    height: 80px;
    background-color: #fff;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .title{
    float: left;
    padding-right: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-family: Sans-serif, Verdana;
    cursor: pointer;
  }
  .login-form {
    float: right;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
  }
  #unihat{
    width: 50px;
    height: 30px;
    margin: 0px;
    padding: 0px;
    margin-left: 10px;
  }
  .writeReview{
    display: flex;
    background-color: rgb(255, 255, 255);
    color: #5e5e5e;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    float: right;
    margin-top: 22px;
    padding-right: 10px;
    padding-left: 10px;
    width: 150px;
    justify-content: center;
  }
  .writeReview:hover {
    background-color: rgb(134, 134, 134);
    color: white;
  }
  .review-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: rgba(227, 83, 50, 0.631);
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    width: 150px;
    margin-right: 50px;
  }
    .review-button:hover {
    background-color: rgba(169, 59, 35, 0.631);
  }

  #writeReview{
    background-color: rgba(227, 83, 50, 0.631);
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bolder;
    padding: 10px;
    width: 100%;
    margin: auto;
    margin-top: 50px;
    font-size: larger;
  }
  #writeReview:hover{
    background-color: rgba(169, 59, 35, 0.631);
  }
  
  #uniLogin{
    color: rgba(227, 83, 50, 0.631);
    margin-right: 0px;
    padding-right: 0px;
  }
  #banner{
    position: relative;
    height: 300px;
  }
  #uniImage{
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    z-index: 1;
    margin-top: 0px;
    padding-top: 0px;
  }
  #banner::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    padding-top: 0px;
    margin-top: 0px;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(to bottom, transparent, black); /* Update the gradient colors */
    opacity: 0.5; /* Adjust the opacity of the gradient overlay */
  }
  

  #bannerText{
    position: absolute;
    bottom: 40px;
    color: white;
    font-size: 16px; /* Adjust the font size */
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
    z-index: 3;
    font-size:40px;
    font-family: Sans-serif, Verdana;

  }

  #NZ{
    font-size:50px;
    font-weight: bold;
  }

  .query-results {
    padding-top: 20px;
    background-color: #f9f8f8f6;
    width: 100%;
  }
  #table {
    width: 60%;
    border-collapse: collapse;
    border-top: 1px solid rgb(206, 206, 206);
    padding: 0px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 50px;
    max-width: 1000px;
    text-align: center;
    max-width: 800px;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
  th,
  #table td {
    padding: 7px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-size: medium;
    font-weight: 300;
  }
  #table th {
    border-bottom: 1px solid #ecebebf6;
    border-top: none;
    font-size: large;
  }
  tr{
    border-radius: 30px;
    font-size: larger;
    cursor: pointer;
  }
  #landingPageTR{
    border-bottom: 1px solid rgb(206, 206, 206);

  }
  .rating{
    margin-top: 10px;
    position: relative;
  }
  .thumb{
    position: relative;
    color: rgb(147, 146, 146);
    font-size: 25px;
    padding: 10px;
  }
  .thumb:hover{
    color: rgb(129, 129, 129);
    cursor: pointer;
  }
  .mythumb{
    position: relative;
    color: rgba(227, 83, 50, 0.631);
    font-size: 25px;
    padding: 10px;
  }
  .mythumb:hover{
    color: rgba(143, 47, 26, 0.631);
    cursor: pointer;
  }
  #numVotes{
    font-size: small;
  }

  #returnToRankings{
    width: 300px;
    margin-top: 20px;
    border-radius: 10px;
    font-size: larger;
    background-color: #0077ffc8;
  }
  #returnToRankings:hover {
    background-color: #075ec1c2;
  }

  .ratingContainer{
    position: relative;
    width: 300px;
    text-align: right;
  }
  #numRatings{
    position: absolute;
    color: grey;
    padding-left: 10px;
    margin-top: 2px;
    font-size: 12px;
    font-weight: bold;
  }
  #uniName{
    position: absolute;
    margin-top: 10px;
    margin-left: 20px;
    font-weight: 200;
    font-size: large;
    border-bottom: 1px solid black  }
  .review{
    background-color: rgb(242, 241, 241);
    color: rgb(43, 43, 43);
    width: 80%;
    max-width: 800px;
    margin: auto;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 500;
  }
  .review label{
    color: black;
    font-weight: bold;
  }
  .review .notes{
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 100;
    color: rgb(29, 29, 29);
    line-height: 22px;
  }
  #aboutPanel{
    background-color: white;
    color: rgb(43, 43, 43);
    width: 80%;
    max-width: 800px;
    margin: auto;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 100;
    border: 1px solid rgba(227, 83, 50, 0.631);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  }
  /* #browseRatings{
    float: left;
    max-width: 50%;
  }
  #aboutUni{
    float: right;
    max-width: 50%;
  } */
  
  .dateReview{
    font-weight: bold;
    font-size: small;
    color: gray;
  }
  .userIndividualRating{
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .otherRatings{
    padding-top: 5px;
    font-weight: 700;
  }
  .ratingsStars{
    text-align: center;
  }
  #divButtonReturnToRankings{
    width: 80%;
    margin: auto;
  }
  #divButtonReturnToRankings button{
    margin-left: 0px;
    width: 100%;
  }
  #overallAverageScore{
    padding-top: 30px;
    width: 80%;
    max-width: 800px;
    margin: auto;

  }
  #averageScoreDetails{
    padding-bottom: 20px;
    text-align: left;
    width: 80%;
    max-width: 800px;
    margin: auto;
    line-height: 30px;
  }
  #UniDetails{
    text-align: left;
  }  
  #browseRatings{
    text-align: center;
  }
  .averagesUni{
    padding-top: 5px;
    padding-bottom:5px;
  }
  .courseChoice{
    font-weight: bolder;
    padding-top: 15px;
    padding-bottom: 5px;
  }
  #goBackButton{
    margin-top: 20px;
    margin-left: 0;
    height: 40px;
    border-radius: 10px;
    font-size:medium;
    width: auto;
    color: black;
    background-color: #f9f8f8f0;
  }
  #goBackButton:hover{
    background-color: rgb(108, 107, 107);
    color: white;
  }
  .BackButton{
    border-radius: 10px;
    width: 130px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 100px;
    height: 50px;
    font-size: larger;
    float: left;
    position: relative;
    color: black;
    background-color: white;

  }
  .BackButton:hover{
    background-color: rgb(108, 107, 107);
    color: white;
  }
  #approveContainer{
    margin-top: 50px;
  }
  #ambassadorMain{
    width: 80%;
    margin: auto;
    margin-top: 50px;
  } 
  #ambassadorMain h1{
    padding-left: 0px;
    margin-left: 0px;
  }
  #name{
    display: block;
    width: 200px;
    border-radius: 10px;
    padding: 10px;
    font-size: medium;
  }
  #selectUniAmbassador{
    display: block;
    border-radius: 10px;
    width: 218px;
    padding: 7px;
    font-size: medium;
  }
  #ambassadorButton{
    display: block;
    border-radius: 10px;
    background-color: rgba(169, 59, 35, 0.631);
    margin: 0px;
    width: 218px;
    padding: 15px;
    font-size: medium;
    font-weight: 300;
  }
  
  @media (max-width: 828px) {
      #subHeaderLanding{
        max-width: 90%;
      }
    #textLanding{
      width: 95%;
    }
    #submissionElements{
      width: 90%;
      margin: auto;
      text-align: center;
    }  
    #submissionElements select{
      width: 80vw;
    }  
    #emailSubInput{
      width: 80%;
    }

    #lastNotes{
      width: 90%;
    }
    #unihat{
      display: none;
      height: 0px;
      width: 0px;
    }
    .title{
      width: 200px;
      margin-left: 5px;
      margin-right: 0px;
      padding-right: 0px;
      font-family: Sans-serif, Verdana;
    }
    .login-form{
      width: 130px;
      padding: 0px;
      margin-right: 0px; 
      margin-left: 0px;
      padding-left: 0px;
      left: 0px;
    }  
    .google-button{
      padding: 0px;
    }
    #banner{
      height: 200px;
    }
    #uniImage{
      height: 200px;
    }
    #banner::after{
      height: 100%;
      position: absolute;
      left: 0;
      content: "";
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
    }
    #index{
      font-weight: bolder;
    }
    #table {
      width: 100%;
    }
    .emblem{
      padding-left: 20px;
    }
    #NZ{
      font-size: 35px;
    }
    #bannerText{
      font-size: 20px;
      bottom: 20px;
    }
    th, #table .ratingContainer{
      padding-left: 100px;
    }
    
    #numRatings{
      padding-left: 5px;
      font-weight: 100;
    }
    #rightArrow{
      padding-left: 0px;
      margin-left: 30px;
    }
    #leftColumn{
      width: 70%;
    }
    .modal-content {
      width: 80%;
    }
    #userEmail{
      width: 70%;
    }
    #review-uni-button{
      width: 95%;
    }
    #writeReviewUniDiv{
      width: 95%;
    }  
    #businessmanImage{
      float: none;
    }
    #businessmanText{
      float: none;
      margin: auto;
      margin-top: 30px;
    }
    #businessmanInfographs{
      width: 90%;
      height: 330px;
      margin-top: 50px;
    }
    #reviewManImage{
      float: none;
    }
    #reviewManText{
      float: none;
      margin: auto;
      margin-top: 30px;
    }
    #reviewManInfograph{
      width: 90%;
      height: 300px;
    }
    .writeReview{
      display: none;
    }
    #LoginReviews{
      display: block;

    }
    footer{
      height: 300px;
    }
  }
  @media (max-width: 400px){
    .title{
      font-size: 25px;
      margin-right: 0px;
      padding-right: 0px;
      padding-top: 5px;
    }
    .button-text{
      font-size: small;
    }
    .review-button{
      width: fit-content;
      margin-right: 10px;
    }
    .login-form{
      width: fit-content;
      padding: 0px;
      margin: 0px;
    }
    #write{
      display: none;
    }
    .emblem{
      margin-right: 5px;
      padding-left: 20px;
    }
    #uniName{
      margin-left: 5px;
      font-size: medium;
    }
    #table .ratingContainer{
      padding-left: 50px;
      text-align: center;
    }
    #rightArrow{
      display: none;
    }
    #table td{
      padding-left: 0px;
    }
    #uniRow{
      margin-left: 0px;
      padding-left: 0px;
    }
    #writeAReviewStars{
      display: none;
    }
    #averagesReviewTable{
      font-size: 13px;
    }
    #reviewTable{
      font-size: 12px;
      width: 90%;
    }
    .BackButton{
      display: none;
    }
  }

