.container {
  max-width: 70vw;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  text-align: center;
}

h1 {
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

input {
  padding: 8px;
  width: 75%;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button {
  padding: 10px 40px; /* Changed padding to make the button wider */
  width: 200px;
  margin: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight:900;
}

button:hover {
  background-color: #0056b3;
}
.title h1 {
  color: #3a3a3a;
  text-align: center;
  margin: 30px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
select{
  margin-top: 5px;
  padding: 5px;
}
